/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './src/styles/tailwind.css';

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
    {element}
  </GoogleReCaptchaProvider>
);
